import * as React from "react";
import Header from "../../Components/Header/Header";
import Benefits from "../../Components/Benefits/Benefits";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const BenefitsPage = () => {
  return (
    <div>
      <Seo locale="it" page="benefits" />
      <Header lang="it" />
      <HamburgerMenu />
      <Benefits lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </div>
  );
};

export default BenefitsPage;
