import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Benefits.scss";
import query from "./query";
import CallToAction from "../CallToAction/CallToAction";
import PropTypes from "prop-types";
import { filterDataByLanguage } from "../../Utils/Language";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import CustomSwiper from "../CustomSwiper/CustomSwiper";

const BenefitsPage = (props) => {
  const data = query();
  const dataBenefits = filterDataByLanguage(
    props.lang,
    data.allContentfulBenefitsPage
  );
  const imagedata = dataBenefits.horizontalSlider;

  return (
    <div className="benefits-page-wrapper">
      <div className="benefits-title-container">
        <Container fluid>
          <Row>
            <Col className={"col-12"}>
              <h1 className="pageTitle">{dataBenefits.pageTitle.pageTitle}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomSwiper slides={imagedata} />
      <div className="benefits-list-container">
        <Container fluid>
          <Row>
            <Col className="col-lg-6 col-12">
              <h2 className="listTitle">{dataBenefits.listTitle.listTitle}</h2>
            </Col>
            <Col className="col-lg-6 col-12">
              <ul className="textList">
                {dataBenefits.textList.map((i, _i) => (
                  <li key={_i} className={"text-entry" + " " + "num_" + _i}>
                    {i}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="big-image-container">
        <Container fluid>
          <Row className="big-image-row">
            <Col>
              <GatsbyImage
                className="bigImage"
                image={getImage(dataBenefits.bigImage)}
                alt={dataBenefits.bigImage.title}
              />
            </Col>
          </Row>
          <Row className="bottom-empty-row text">
            <Col className="col-lg-6 col-0 bottom-empty-col"> </Col>
            <Col className="col-lg-6 col-12 bottomText">
              {dataBenefits.bottomText.bottomText}
            </Col>
          </Row>
          <Row className="bottom-empty-row calltoaction">
            <Col className="col-lg-6 col-0 calltoaction-empty-col"> </Col>
            <Col className="col-lg-6 col-12 calltoaction-container">
              <CallToAction
                className="benefits-calltoaction"
                to={dataBenefits.careersLink}
                label={dataBenefits.careersLinkText}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

BenefitsPage.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default BenefitsPage;
