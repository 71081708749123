import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    {
      allContentfulBenefitsPage {
        nodes {
          node_locale
          pageTitle {
            pageTitle
          }
          horizontalSlider {
            gatsbyImageData
            file {
              url
            }
            title
          }
          listTitle {
            listTitle
          }
          textList
          bigImage {
            gatsbyImageData
            file {
              url
            }
            title
          }
          bottomText {
            bottomText
          }
          careersLink
          careersLinkText
        }
      }
    }
  `);
};

export default query;
